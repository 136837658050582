import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Quiz from '../components/quiz/quiz';
import ExternalLayout from '../components/layout/external-layout';

const useStyles = makeStyles(() => ({
  text: {
    color: 'white',
    fontFamily: 'Advent Pro',
  },
  intro: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Advent Pro',
  },
  big: {
    fontSize: 30,
  }
}));

export default function QuizPage() {
  const classes = useStyles();
  const [started, setStated] = useState(false);

  return (
    <ExternalLayout>
      {started ? <Quiz /> : (
        <div className={classes.intro}>
          <p className={`${classes.text} ${classes.big}`}>Faça o teste e descubra o tipo de decoração que mais combina com você.</p>

          <p className={classes.text}>Você é único, descubra o seu estilo.</p>

          <br />
          <Button onClick={() => setStated(true)} size="large" color="primary" style={{ color: 'white' }} variant="outlined">Iniciar</Button>
        </div>
      )}
    </ExternalLayout>
  );
}